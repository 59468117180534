.gallerys{
    min-height: 70vh;
    padding-bottom: 2rem;
    text-align: center;
    background: rgba(221, 221, 221, 0.25);
}
.gallerys .swiper{
    overflow: visible !important;
}
.gallerys h1{
    font-size: 4rem;
    font-weight: 700;
    padding: 1.5rem 0 2rem 0;
    color: #333;
    text-align: center;
}
.gallerys .swiper-pagination{
    transform: scale(1.5);
}
.gallerys .swiper-pagination-bullet{
    background: #66f;
    border: 1px solid #66f;
}
#galCont{
    margin: 0 auto;
    padding: 3rem 0 4rem 0;
    max-width: 55vw;
    overflow-x: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
#galCont .swiper-slide{
    width: unset;
    user-select: none;
    overflow: hidden;
}
#galCont .swiper-slide span{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 4px;
    display: block;

    background: #66f;
    color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
#galCont img{
    height: 250px;
    width:  300px;

    border-radius: 6px;
}
@media(max-width:991px){
    #galCont{
        margin: 2rem 0.25rem;
        padding: 3rem 0 4rem 0;
        max-width: unset !important;
    }
}
@media(max-width:600px){
    .gallerys h1{
        font-size: 3.25rem;
    }
}