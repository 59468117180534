@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

.k-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100000;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.k-navbar-abs{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.k-navbar .left img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.50);
}
.k-navbar .left {
  flex: 1;
  display: flex;
  align-items: center;
}
.k-navbar .right {
  flex: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.k-navbar .left h1 {
  font-size: 2.25rem;
  font-weight: 700;
  color: #fff;
  margin: 0 0.5rem;
  font-family: "Roboto", sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.199);
}
.k-navbar .right {
  display: flex;
  color: #fff;
}
.k-navbar .right a {
  font-size: 1.12rem;
  margin: 0 1rem;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}
.k-navbar .right .login {
  font-size: 1.12rem;
  padding: 10px 24px;
  margin: 0.25rem 0.65rem;
  border: 1px solid transparent;
  background: #66f;
  border-radius: 50px;
  display: block;
}
.k-navbar .right .signup {
  font-size: 1.12rem;
  padding: 10px 24px;
  margin: 0.25rem 0.65rem;
  color: #66f;
  background: #fff;
  border: 1.5px solid transparent;
  border-radius: 50px;
  display: block;
}
.k-navbar .btn {
  margin: 0 1rem;
  display: none;
}
.k-navbar .btn i {
  font-size: 1.5rem;
  padding: 0.2rem;
  transform: scale(1.4);
}

@media screen and (max-width: 991px) {
  .dark-text{
    color: #333 !important;
  }
  .k-navbar .right .signup {
    border-color: #66f;
  }
  .k-navbar {
    padding: 1rem 0 !important;
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.15);
  }
  #kNavbar{
    max-width: unset !important;
  }
  .k-navbar .left {
    padding-left: 0.5rem;
    z-index: 1000;
  }
  .k-navbar .left img {
    height: 40px;
    width: 40px;
  }
  .k-navbar .left h1 {
    font-size: 2rem;
  }
  .NONE {
    display: none !important;
  }
  .k-navbar .btn {
    margin: 0 0.5rem;
    display: block;
  }
  /* Responsive navbar => */
  .k-navbar .right {
    flex-direction: column;
    position: absolute;
    max-width: unset !important;
    top: 76.4px;
    left: 0;
    right: 0;
    padding: 1rem;
    animation: animeWhite 0.5s ease;
    transition: 0.5s all ease-in-out;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .k-navbar .right a {
    margin: 0.5rem 0;
    font-size: 1.25rem;
    color: #333;
  }
  .k-navbar .right .btn {
    margin: 1.25rem 0.75rem 0 0.75rem;
    font-size: 1rem;
    padding: 10px 0;
    min-width: 100px;
    text-align: center;
  }
}
@keyframes k-navbar {
  
}
/* ProfileIcon */

.k-navbar .right img{
  height: 40px;
  width:  40px;
  object-fit: cover;
  border-radius: 50%;
}

/* Animations */
.transWhite{
  animation: animeWhite 0.5s ease;
  transition: 0.5s all ease-in-out;
  background: #fff;
}

@keyframes animewhite {
  0%{
    opacity: 0.1;
    background: transparent;
  }
  100%{
    opacity: 1;
    background: #fff;
  }
}