#signup .changedBG{
    background-image: url(../Signup//assets/3.jpg) !important;
}
.login-form .imput {
    margin: 2rem 0;
}
.login-form .imput span{
    margin-bottom: 0.5rem !important;
}

#signup .top-link{
    position: absolute;
    display: flex;
    align-items: center;

    padding: 1rem;

    text-decoration: none;
    font-weight: 500;
    font-size: 1.75rem;
    letter-spacing: 1px;
}
#signup .top-link i{
    font-size: 1.25rem;
    margin: 0 0.25rem;
}