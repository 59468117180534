.FaqSection{
    min-height: 80vh;
    overflow-y: hidden;
    background: linear-gradient(127deg,#DA70D6,#CCCCFF);
}
.FaqContainer{
    height: 100%;
}
.FaqContainer h1{
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    text-align: center;
    line-height: 2;
}
.FaqContainer .main{
    padding: 1.75rem 0 4rem 0;
    display: flex;
    justify-content: center;
}
.FaqContainer .accordion-item{
    border: none;
    background: transparent;
    margin: 1rem 0;
    width: 60vw;
}
.FaqContainer .accordion-header,
.FaqContainer .accordion-header button{
    font-size: 1.2rem;
    border-radius: 8px !important;
    transition: 0.5s all ease;
}
.FaqContainer .accordion-header button{
    width: 100%;
    text-align: start;
    display: flex;
    align-items: center;
    font-weight: 600;
    background: #fff;
    color: #66f;
    border: none;
    padding: 1.5rem 1rem;
}
.FaqContainer .accordion-header:hover{
    transition: 0.5s all ease;
}
.FaqContainer .accordion-header button .badge{
    float: left;
    padding: 0.25rem 0.45rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    color: #fff;
    background-color: #66f;
}
.FaqContainer .accordion-body{
    margin-top: 0.35rem;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
}
.FaqContainer .accordion-button:focus {
    z-index: 0 !important;
    outline: 0 !important;
    border: none !important;
    box-shadow: none !important;
}
.FaqContainer .accordion-button::after {
    /* color: white !important; */
    background-blend-mode: color;
}
@media(max-width:990px){
    .FaqContainer h1{
        line-height: 1.5;
    }
}
@media(max-width:600px){
    #FaqContainer{
        margin-top: 0.75rem;
        padding: 0 1rem;
    }
    .FaqContainer h1{
        text-align: center;
        font-size: 2.75rem;
        line-height: 1.25;
    }
    .FaqContainer h1 span{
        display: block;
    }
    .FaqContainer .accordion-item{
        width: 100%;
    }
    .FaqContainer .main{
        padding: 2rem 0 3rem 0;
    }
    .FaqContainer .accordion-header button{
        width: 100%;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        display: flex;
    }
    .FaqContainer .accordion-header button .badge{
        align-self: flex-start;
        margin-top: 0.3rem;
    }
}