#shps4{
    background: radial-gradient(100% 100% at 50% 100%, #162E6D 0%, #071128 100%),linear-gradient(
    0deg, #FFFFFF, #FFFFFF);
}
.hps4{
    min-height: 70vh;
    padding: 1rem 0 2rem 0;
}
.hps4 h1{
    font-size: 3.1rem;
    font-weight: 700;
    margin: 1.5rem 0 2rem 0;
    color: #fff;
    text-align: center;
}
.hps4 article{
    font-size: 1.1rem;
    line-height: 2;
    padding: 0 10vw;
    text-align: center;
    color: #fff;
}
.hps4 .content{
    margin-top: 1.5rem;
    padding: 1rem 2rem;
    height: auto;
}
.hps4 .hideOverflowOnPhone{
    overflow: hidden;
    display: flex;
    justify-content: space-around;
}
.hps4 .hideOverflowOnPhone .k-mode{
    background: rgba(255, 255, 255, 0.125);
    backdrop-filter: blur( 5px );
    width: 45%;
    padding: 1.5rem 1rem;
    margin: 1.5rem 0;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
}
.hps4 .hideOverflowOnPhone .k-mode .img-container{
    position: relative;
    width: 120px;
    padding: 0.5rem;
    /* background-color: #ff1; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.hps4 .hideOverflowOnPhone .k-mode .img-container img{
    z-index: 1;
    height: 100px;
    width:  100px;
    object-fit: contain;
}
.hps4 .hideOverflowOnPhone .k-mode .img-container .blob{
    position: absolute;
    z-index: 0;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    opacity: 0.8;
    transform: scale(1.25);
    height: 100%;
    width: 100%;
}
.hps4 .hideOverflowOnPhone .k-mode .info-container{
    width: calc(100% - 120px);
    /* background-color: #ff156f; */
}
.hps4 .hideOverflowOnPhone .k-mode .info-container h3{
    font-size: 1.6rem;
    color: #fff;
    font-weight: 600;
    margin-bottom: 1.0rem;
}
.hps4 .hideOverflowOnPhone .k-mode .info-container p{
    color: #fff;
}


@media(max-width:991px){
    .hps4 .hideOverflowOnPhone{
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 1rem;
    }
    .hps4 .hideOverflowOnPhone .k-mode{
        max-width: unset;
        flex-direction: column;
        align-items: center;
        margin: 0.65rem 0;
        width: 100%;
    }
    .hps4 .hideOverflowOnPhone .k-mode .img-container,
    .hps4 .hideOverflowOnPhone .k-mode .info-container{
        width: 100%;
        text-align: center;
    }
    .hps4 .hideOverflowOnPhone .k-mode .img-container{
        padding: 1rem 0;
    }
    .hps4 .hideOverflowOnPhone .k-mode .img-container img{
        height: 180px;
        width: 100%;
        object-fit: contain;
    }
    .hps4 .content{
        padding: 0;
    }
    .hps4 h1{
        font-size: 2.1rem;
    }
    .hps4 article{
        padding: 0 0.75rem;
    }
}