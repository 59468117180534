.hero-res{
    height: 400px;
    background-color: #E100FF;
    background-image: url(https://wallpapercave.com/wp/wp8345873.png);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;

    position: relative;
    overflow: hidden;
    color: #fff;
}
.hero-res .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
}
.hero-res .overlay h1{
    font-size: 5rem;
    text-align: center;
    font-weight: 700;
    padding: 1rem;
}
#freres{
    min-height: 80vh;
    background: rgb(250, 250, 250);
}
#freres .container{
    height: 100%;
    padding-bottom: 5rem;
}
#freres .container h1{
    font-size: 3rem;
    padding: 1rem 1.5rem 1rem 1rem;
    font-weight: 700;
    color: #333;
}
#freres .container h1::after{
    content: '';
    display: block;
    margin: 0.5rem auto auto 0.5rem;
    height: 2px;
    width:  150px;
    background: #999;
}
#freres .videos-container{
    display: flex;
    flex-wrap: wrap;
}
#freres .videos-container .demo-item{
    padding: 1.5rem;
    margin: 1px;
    width: 33%;
}
#freres .videos-container .demo-item iframe{
    width: 100% !important;
    border-radius: 6px;
    flex: 1;
    transition: 0.5s all ease-in-out;
}
#freres .videos-container .demo-item iframe:hover{
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
    transition: 0.5s all ease-in-out;
}
@media(max-width:991px){
    .hero-res{
        height: 280px;
        background-position: center right;
    }
    #freres .videos-container .demo-item{
        width: 100%;
        padding: 0.6rem;
    }
    #freres .container h1{
        text-align: center;
        font-size: 2.25rem;
    }
    #freres .container h1::after{
        margin: 0.5rem auto 1rem auto;
    }
    .hero-res .overlay h1{
        margin-bottom: -2rem;
        font-size: 3rem;
    }
}