.shsc6 {
  min-height: 75vh;
  background: linear-gradient(127deg,#4568dc,#4568dc 100%);
  color: #fff;
  display: flex;
  align-items: center;
}
.hsc6 {
  height: auto;
  /* background: #ff56; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
}
.hsc6 .event-info-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* background: rgba(0, 0, 255, 0.459); */
    width: 60%;
}
.hsc6 .event-cards-container{
    /* background: rgba(1, 255, 1, 0.315); */
    padding: 1.5rem 0;
    width: 40%;
}
.hsc6 .event-info-container h1{
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 2rem;
}
.hsc6 .event-info-container article{
    font-size: 1.25rem;
    line-height: 1.75;
    font-weight: 400;
}
.hsc6 .swiper {
  height: 325px;
  width:  325px;
}
.hsc6 .swiper-slide {
  user-select: none;
  background: #fff;
  border-radius: 16px;
}
.hsc6 .swiper-slide img{
    width: 100%;
    object-fit: contain;
}

@media(max-width:991px){
    .hsc6 {
        flex-direction: column;
    }
    .hsc6 .event-cards-container,
    .hsc6 .event-info-container{
        width: 100%;
        text-align: center;
    }
}
@media(max-width:600px){
    #hsc6{
        padding: 1rem 0;
        margin: 0;
        max-width: unset;
    }
    .hsc6 .event-info-container h1{
        font-size: 3.2rem;
        margin-bottom: 0;
    }
    .hsc6 .event-info-container article{
        padding: 1rem;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.75;
    }
    .hsc6 .event-cards-container{
        padding: 2rem 0;
    }
}