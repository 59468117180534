.shps5{
    background-color: #000000;
    background-image: linear-gradient(147deg, #00cdac  , #02aab0);
    height: auto;
    color: #fff;
}
.hps5{
    height: auto;
    display: flex;
    padding: 1rem 0;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    /* background-color: rgba(255, 255, 255, 0.6); */
}
.hps5 h1{
    font-size: 4.5rem;
    margin-bottom: 2rem;
    font-weight: 700;
}

.hps5 .info-container{
    width: 60%;
}
.hps5 .info-container article{
    font-size: 1.25rem;
    padding-right: 3rem;
    line-height: 1.75;
}
.hps5 .swiper-container{
    width: 40%;
    max-width: 500px;
    margin-top: 3rem;
    padding: 1.5rem 0;
    /* background-color: #ff156f; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.hps5 .slide{
    width:  350px;
    min-height: 400px;
    margin: auto auto;
    border-radius: 8px;
    padding: 0.75rem;
    word-wrap: break-word;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.35);
}
.swiper-button-next, .swiper-button-prev {
    color: rgba(255, 255, 255, 0.9) !important;
}
.hps5 .slide .details-container{
    /* background: #f56; */
    color: #fff;
}
.hps5 .slide .img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    /* background: #ff56; */
}
.hps5 .slide .img-container img{
    height: 140px;
    width: 140px;
    border-radius: 50%;
    object-fit: contain;
}
.hps5 .slide .details-container h4{
    font-size: 1.65rem;
    font-weight: 700;
}
.hps5 .slide .details-container h6{
    font-size: 1rem;
}

.hps5 .slide .details-container article{
    font-size: 0.9rem;
    line-height: 1.5;
}
@media(max-width:991px){
    .hps5{
        flex-direction: column;
        text-align: center;
    }
    #hps5{
        width: 100%;
        max-width: unset;
        margin: 0 0;
        padding: 1rem;
    }
    .hps5 .info-container,
    .hps5 .swiper-container{
        width: 100%;
    }
    .hps5 .info-container article{
        padding: 1rem;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.75;
    }
}
@media(max-width:600px){
    #hps5 h1{
        font-size: 2.75rem;
        margin-bottom: 0.5rem;
    }
    .hps5 .swiper-container{
        margin-top: 0.5rem;
    }
}
@media(max-width:470px){
    .hps5{
        padding: unset 0;
        margin: 0 0;
        text-align: center;
    }
    .hps5 .slide{
        min-height: 350px;
        height: auto;
        width: 90%;
        background-color: rgba(255, 255, 255, 0.25);
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
    .swiper-button-next, .swiper-button-prev {
        /* display: none !important; */
        transform: scale(0.65);
    }
    #hps5 h1{
        font-size: 3rem;
    }
    .hps5 .slide .details-container article{
        line-height: 1.7;
    }
}