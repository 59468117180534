.footer{
    padding: 1.5rem 1.5rem 4.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    background: #202020;
    color: #fff;
}
.footer h1{
    font-size: 3.25rem;
    font-weight: 700;
    letter-spacing: 1px;
}
.footer h4{
    font-size: 1.75rem;
    font-weight: 600;
    margin: 1rem 0;
}
.footerChild:nth-child(1){flex: 4;padding: 0 2rem 0 1rem;display: flex;flex-direction: column;}
.footerChild:nth-child(1) p:nth-child(2){
    margin-top: 1rem;
    line-height: 2;
}
.footerChild:nth-child(1) p:nth-child(3){
    margin-top: 1.5rem;
}

.footerChild{flex: 2;padding: 0 1rem;display: flex;flex-direction: column;}
.footer p{
    font-size: 1.25rem;
    line-height: normal;
    margin: 0;
}
.footer a{
    margin: 0.25rem 0;
    padding: 4px 0;
    display: flex;
    align-items: center;

    font-size: 1.1rem;
    line-height: 1.85;
    font-weight: 400;
    text-decoration: none;
    color: #fff;
    transition: 0.5s all ease-in-out;
}
.footer a:hover{
    color: #ff1;
    transition: 0.5s all ease-in-out;
}
.footer .footerChild:nth-child(2) i,
.footer .footerChild:nth-child(4) i{
    padding: 8px;
    border-radius: 50%;
    background-color: #66f;
    font-size: 1rem;
    color: #fff;
    margin-right: 0.5rem;
}
.footer .footerChild:nth-child(2) .fa-facebook-f{
    padding: 8px 10.5px;
}
@media(max-width:990px){
    .footer{
        flex-direction: column;
        padding: 1.5rem 1rem;
    }
    .footerChild:nth-child(1){flex: 1;padding: 0 0;}
    .footerChild{flex: 1;padding: 0 0;}
    .footer .footerChild:nth-child(2) i,
    .footer .footerChild:nth-child(4) i{
        margin-right: 0.5rem;
    }
}
.bot-footer{
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    background: #f5f5f5;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    font-weight: 600;
}
@media(max-width:600px){
    .bot-footer{
        flex-direction: column;
        font-weight: 600;
    }
    .bot-footer span{
        margin: 0.5rem 0;
        text-align: center;
    }
    .footer p{
        font-size: 1rem;
    }
    .footerChild:nth-child(1) p:nth-child(2) {
        margin-top: 0.5rem;
        line-height: 1.75;
    }

}