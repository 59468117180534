/* BREE SERIF FONT I LIKE IT MAYBE I'LL USE IT LATER */
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
/* BREE SERIF FONT I LIKE IT MAYBE I'LL USE IT LATER */
#shsc3{
      background: linear-gradient(157deg,#ff512f,#dd2476);
}
.hsc3{
    min-height: 85vh;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
}
.hsc3 .info-container,
.hsc3 .img-container{
    padding: 1rem;
}
.hsc3 .info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding-right: 2.5rem;
    /* background-color: gold; */
}
.hsc3 .info-container img{
    display: none;
}
.hsc3 .img-container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(35, 199, 35); */
}
.hsc3 .img-container img{
    width: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    background: azure;
}
.hsc3 .info-container h1{
    color: #333;
    font-size: 3.75rem;
    font-weight: 700;
    color: #fff !important;
    margin-bottom: 1.5rem;
}
.hsc3 .info-container h1 span{
    display: block;
}
.hsc3 .info-container p{
    font-size: 1.25rem;
    line-height: 1.75;
    color: #fff !important;
}
.hsc3 .info-container .btn-container{
    margin: 1.5rem 0 0 0;
}
.hsc3 .info-container .btn-container a{
    color: #ff512f;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 1.1rem;
    padding: 0.90rem 1.75rem;
    background: #fff;
    text-decoration: none;
    border-radius: 4px;
    margin: 0.5rem;
}


@media(max-width:991px){
    .hsc3 .img-container{
        display: none;
        width: 0;
    }
    .hsc3 .info-container{
        width: 100%;
        padding: 1rem;
        justify-content: flex-start;
    }
    .hsc3 .info-container img{
        display: block;
        padding: 1rem;
        border-radius: 8px;
        background-color: azure;
        margin: 1.75rem 0 1.5rem 0;
        /* min-height: 200px; */
    }
    .hsc3 .info-container .btn-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hsc3 .info-container p{
        text-align: center;
    }
}
@media(max-width:600px){
    .hsc3{
        height: auto;
        min-height: 0;
    }
    #hsc3{
        margin: 0 0 0rem 0;
        padding: 0 0 3rem 0;
    }
    .hsc3 .info-container{
        padding: 0.75rem 0.75rem;
    }
    .hsc3 .info-container h1{
        font-size: 2.75rem;
        margin: 0.75rem 0;
        text-align: center;
    }
    .hsc3 .info-container p{
        font-size: 1.2rem;
    }
    .hsc3 .info-container .btn-container{
        margin: 0 0 0 0;
    }
}