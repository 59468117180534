@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
#signup{
    height: 100vh;
    color: #333;
    background: lightblue;
    font-family: 'Poppins', sans-serif;
    display: flex;
    overflow: hidden;
}
#signup .left{
    min-height: 100vh;
    overflow-y: scroll;
    width: 45vw;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#signup .left::-webkit-scrollbar{
    display: none;
}
#signup .right{
    position: relative;
    width: 55vw;
    background-image: url(./assets/2.jpg);
    background-size: cover;
    background-position: center center;
}
#signup .right .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
}

#signup .right .hero{
    padding: 0 3rem;
}
#signup .right .hero h1{
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.5;
}
#signup .right .hero img{
    border-radius: 50%;
    transform: scale(1.5);
    display: block;
    margin: 1rem auto;
}
#signup .hero p{
    line-height: 1.65;
    font-size: 1.25rem;
}
/* Right Section OVER HERE*/


/* Left Section */
#signup .left .auth-container{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
#signup .left .auth-card{
    margin: 2rem auto 2rem auto;
    width: 70%;
    /* height: ; */
    border-radius: 8px;
    padding: 1.5rem 1.5rem;
    border: 2px solid rgba(0, 0, 0, 0.2);
}
#signup .left .auth-card h3{
    font-size: 2.5rem;
    font-weight: 700;
}
#signup .left .auth-card .imput{
    margin: 1rem 0;
}
#signup .left .auth-card .imput span{
    font-weight: 600;
    display: block;
    margin-bottom: 4px;
}
#signup .left .auth-card .imput input{
    padding: 11px 16px;
    border: 2px solid rgba(0, 0, 0, 0.2);
}
#signup .left .auth-card .imput input:focus{
    box-shadow: none;
    border-color: #66f;
}
#signup .left .auth-card .imput input::placeholder{
    font-size: 0.95rem;
}


#signup #submit-signup{
    margin-top: 1.25rem;
    border-radius: 6px;
    padding: 11px 0;
    font-size: 1.25rem;
    border: none;
}

#signup .inactive-btn{
    background: rgba(164, 164, 255, 0.87) !important;
}







@media(max-width:991px){
    #signup .right{display: none;}
    #signup .left{
        width: 100%;
        z-index: 100;
        /* background-color: transparent; */
        color: #333;
    }
    #signup .left .auth-card{
        border: none;
    }
}

.mobi{
    display: none !important;
}

@media(max-width:600px){
    .mobi{
        display: block !important;
    }
    #signup .left .auth-container{
        padding: 0 0;
    }
    #signup .left .auth-card{
        width: 100%;
        padding: 1rem;
        margin: 1rem auto 1.5rem auto;
    }
}
@media(max-width:380px){
    .blokk{
        display: block;
    }
    #signup .left .auth-card h3{
        line-height: 1.5;
        font-size: 2.8rem;
        margin-bottom: -10px;
    }
}

#signup .imput .left-icon,
#signup .imput .right-icon{
    position: relative;
    background-color: #ff56;
}
#signup .imput .left-icon i,
#signup .imput .right-icon i{
    position: absolute;
    display: block;
    cursor: pointer;
    right: 0;
    top: 0;
    padding: 1rem;
    bottom: 0;
    font-size: 1.1rem;
    border-radius: 4px;

    background-color: #66f;
    color: #fff;
}
#signup .imput .left-icon input{
    padding-left: 3.6rem !important;
}
#signup .imput .left-icon i{
    right: unset;
    left: 0;
}
#signup .imput .left-icon .fa-phone-alt{
    transform: rotateY(180deg);
}


.signup-footer{
    padding: 1.25rem 1.5rem;
    background: rgba(218, 220, 224, 0.3);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
}
.signup-footer a{
    text-decoration: none;
    font-size: 1rem;
}

#signup .left .backbtn{
    background-color: #55f;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.158);
}

.zoom-out-signup-form{
    animation: zoom-out-signup-form 0.3s ease-in;
}
@keyframes zoom-out-signup-form{
    0%{
        opacity: 0.8;
        transform: scale(0.85);
    }
    100%{
        opacity: 1;
        transform: scale(1.0);
    }
}