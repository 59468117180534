.hsc1{
    display: flex;
}
.hsc1 .left,
.hsc1 .right
{
    min-height: 90vh;
    padding: 0.5rem;
}
.hsc1 .right{
    width: 50%;
    /* background-color: rgba(0, 0, 255, 0.103); */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.hsc1 .left{
    width: 50%;
    /* background-color: #ff56; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.hsc1 .left span{
    color: #0066ff;
    font-size: 3.75rem;
    font-weight: 700;
    margin-bottom: -12px;
}
.hsc1 .left h1{
    font-size: 3.2rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
}
.hsc1 .left p{
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75;
    color: #333;
}
.hsc1 .left .btn-container{
    margin: 1.5rem 0;
}
.hsc1 .left .btn-container a{
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    background: #0066ff;
    padding: 12px 22px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.123);
    border-radius: 6px;
    letter-spacing: 0.5px;
}
.hsc1 .left .btn-container a:hover{
    box-shadow: 0 8px 15px -6px rgba(0, 0, 0, 0.4);
}
.hsc1 .right img{
    height: 375px;
}

@media(max-width:1000px){
    .hsc1{
        flex-direction: column-reverse;
    }
    .hsc1 .left,.hsc1 .right{
        width: 100%;
        min-height: 0;
    }
}

@media(max-width:600px){
    .hsc1 .left h1{
        font-size: 2.1rem;
    }
    .hsc1 .left span{
        font-size: 2.5rem;
        margin-bottom: -4px;
    }
    .hsc1 .left p{
        font-size: 1.1rem;
        line-height: 1.75;
    }
    .hsc1 .right img{
        height: 200px !important;
    }
    .hsc1 .left .btn-container{
        background-color: #fff;
        margin: 0.5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hsc1 .left .btn-container a{
        font-size: 1rem;
        padding: 14px 18px;
    }
    #hsc1{
        padding: 0.35rem;
    }
}
@media(max-width:330px){
    .hsc1 .left .btn-container {
        margin: 0.25rem 0;
    }
    .hsc1 .left .btn-container a{
        display: block;
        margin: 0.5rem 0 !important;
        text-align: center;
    }
}