.sectionhsc2{
    position: relative !important;
    overflow: hidden;
}
.sectionhsc2 .circletl{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    height: 600px;
    width:  600px;
    top:  -300px;
    left: -300px;
    background: linear-gradient(60deg, #72f7bd 0%, #00d2dd 48%, #32cffa 100%);
}
.sectionhsc2 .circletrd{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top: -100px;
    right: -150px;
    height: 300px;
    width:  300px;
    background: linear-gradient(150deg,rgba(0, 255, 255, 0.349),violet);
}
.sectionhsc2 .circlebr{
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    bottom: -200px;
    right:  -200px;
    height: 400px;
    width:  400px;
    background: linear-gradient(150deg,chartreuse,aqua);
}
.sectionhsc2 .circlebl{
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    bottom: -150px;
    left:   -150px;
    height: 400px;
    width:  400px;
    background: linear-gradient(150deg,#ff156f,#ff156d);
}

@media(max-width:1100px){
    .sectionhsc2 .circletl{
        height: 400px;
        width:  400px;
        top:  -200px;
        left: -200px;
    }
    .sectionhsc2 .circletrd{
        background: linear-gradient(150deg,chartreuse,aqua);
    }
}
@media(max-width:600px){
    .sectionhsc2 .circletl,
    .sectionhsc2 .circletrd,
    .sectionhsc2 .circlebr,
    .sectionhsc2 .circlebl{
        display: none;
    }
}

.hsc2{
    padding-top: 0.5rem;
    z-index: 10;
    /* background-color: #ff65; */
    position: relative;
}
.hsc2 h1{
    font-size: 3.20rem;
    font-weight: 700;
    color: #333;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    text-align: center;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.09); */
}
.hsc2 h1 span{
    color: #0066ff;
}
.hsc2 #playpic{
    height: calc(340px + 3rem);
    background: linear-gradient(127deg, #1fc5fa 0, #0f97f3 100%);
    border-radius: 8px;
    padding: 2rem;
    z-index: 10;
    border: none;
}
.hsc2 article{
    font-size: 1.5rem;
    z-index: 10;
    line-height: 1.75;
    text-align: center;
    padding: 2rem 5rem;
}

@media(max-width: 990px) {
    .hsc2 article{
        padding: 1rem 2rem;
    }
}
@media(max-width:600px){
    #hsc2{
        padding: 0;
    }
    .hsc2 h1{
        font-size: 2.25rem;
        padding: 1rem 1rem 0.5rem 0.5rem;
        margin-bottom: 1.5rem;
    }
    .hsc2 #playpic{
        height: unset;
        width: 100%;
        padding: 1rem;
        border-radius: 0;
    }
    .hsc2 article{
        font-size: 1.1rem;
        padding: 1.25rem 0.85rem;
    }
}






/* Card Portion */

.hsc2 .about-us-cards-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* padding: 1rem 0; */
    /* background-color: #ff56; */
}
.hsc2 .about-us-cards-container .hideOverflowOnMobile{
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0;
}
.hsc2 .k-card{
    width: 30%;
    height: 400px;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 12px;
    color: #fff;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.15);
}
.hsc2 .k-card .img-container{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    border-radius: 8px;
    background: azure;
}
.hsc2 .k-card img{
    height: 180px;
    width: 260px;
    object-fit: contain;
}
.hsc2 .k-card h3{
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.75rem 0 0.5rem 0;
}
.hsc2 .k-card p{
    padding: 0 0.2rem;
}
@media(max-width:990px){
    .hsc2 .about-us-cards-container .hideOverflowOnMobile{
        flex-direction: column;
        padding: 0;
    }
    .hsc2 .about-us-cards-container .colrev{
        flex-direction: column-reverse;
    }
    .hsc2 .k-card{
        width: 100%;
        height: auto;
        margin: 0;
        border-radius: 0;
        padding: 1rem 0.75rem;
        border-bottom: 1px solid #fff;
    }
    .hsc2 .k-card img{
        height: unset;
        min-height: 175px;
    }
    .hsc2 .k-card p{
        padding: 0 0;
    }
}


.blue{
    background: linear-gradient(
127deg, #1fc5fa 0, #0f97f3 100%);
}
.yellow{
    background: linear-gradient(
127deg, #f9ca5b 0, #f76d1d 100%);
}
.purple{
    background: linear-gradient(
        127deg, #824db6 0, #8819a9 100%) 0 0;
}        
.pup{
    background: #7F00FF;
    background: -webkit-linear-gradient(127deg, #E100FF, #7F00FF);
    background: linear-gradient(127deg, #E100FF, #7F00FF);
}
.tomato{
    background: #ec008c;
    background: -webkit-linear-gradient(127deg, #fc6767, #ec008c);
    background: linear-gradient(127deg, #fc6767, #ec008c);
}
.orange{
    background: #FF8008;
    background: -webkit-linear-gradient(127deg, #FFC837, #FF8008);
    background: linear-gradient(127deg, #FFC837, #FF8008);

}
.green{
    background-image: linear-gradient(-225deg, #72f7bd 0%, #00d2dd 48%, #32cffa 100%);
}
.red{
    background: transparent linear-gradient(
        127deg, #f16969 0, red 100%) 0 0 no-repeat padding-box;
}