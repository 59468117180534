@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap');
#slideShoww{
    height: 100vh;
    font-family: 'Montserrat', sans-serif !important;
}
#slideShoww
.overlay{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: rgba(0, 0, 0, 0.4);

    color: #fff;
}
#slideShoww
.overlay h1{
    font-size: 5.5rem;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
    margin-bottom: 0;
}
#slideShoww
.overlay p{
    font-size: 1.5rem;
    margin: 1rem 0 2rem 0;
}

#slideShoww
.overlay .btn-container{
    margin-top: 0;
}
#slideShoww
.overlay button{
    font-size: 1.25rem;
    font-weight: 600;
    padding: 1rem 2.5rem;
    border-radius: 50px;
    border: 1px solid transparent;
}
.overlay button a{
    color: unset;
    text-decoration: none;
}
#slideShoww
.overlay button:nth-child(1){
    color: #fff;
    background: #66f;
}
#slideShoww
.overlay button:nth-child(2){
    color: #66f;
    background: #fff;
    border-color: #66f;
}
#slideShoww .swiper-slide img{
    width: 100%;
    object-fit: cover;
    height: 100vh;
}
#slideShoww .swiper-pagination-bullet{
    background: #ddd;
}
#slideShoww .swiper-pagination-bullet-active{
    background: #fff;
}

@media(max-width:800px){    
    #slideShoww
    .overlay{
        text-align: center;
    }
    #slideShoww
    .overlay h1{
        font-size: 3.5rem;
    }
    #slideShoww
    .overlay p{
        font-size: 1.25rem;
        padding: 0 1rem;
    }
    #slideShoww .swiper-button-prev,
    #slideShoww .swiper-button-next{
        display: none;
    }
}
@media(max-width:600px){
    #slideShoww
    .overlay h1{
        font-size: 3rem;
    }
    #slideShoww
    .overlay p{
        font-size: 1.2rem;
    }    
    #slideShoww
    .overlay button{
        font-size: 1rem;
        font-weight: 600;
        padding: 1rem 2rem;
        border-radius: 50px;
        border: 1px solid transparent;
    }
    #slideShoww{
        height: 95vh;
        overflow-y: hidden;
    }
}